import React from "react"
import Layout from "../components/layouts/Layout.js"
import Banner from "../components/Banner.js"

const Thanks = () => {
  return (
    <Layout>
      <Banner />
      <h3 style={{ marginTop: "120px" }}>Thanks for your message!</h3>
      <p style={{ textAlign: "center", margin: "auto" }}>
        We'll get back to you soon
      </p>
    </Layout>
  )
}

export default Thanks
